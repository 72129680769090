import * as React from 'react';

import icon from './icon-192x192.png';

const Logo = () => {
    return (
        <img src={icon} height={40} alt="Logo" />
    );
};

export default Logo;