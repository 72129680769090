import * as React from "react";
import { List, SimpleList, Datagrid, UrlField, BooleanField, TextField, DateField, SimpleForm, TextInput, Edit, BooleanInput } from 'react-admin';
import { SaveButton, DeleteButton, Toolbar } from 'react-admin';
import ScrapEditActions from '../components/actions/ScrapEditActions';
import ScrapButton from '../components/actions/ScrapButton';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';


export const FeedList = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  return (
    <List {...props} sort={{ field: 'isoDate', order: 'DESC' }} perPage={25} pagination={false} >
      {isSmall ? (
        <SimpleList
            primaryText={record => record.link}
            secondaryText={record => record.provider}
            tertiaryText={record => new Date(record.isoDate).toLocaleDateString()}
        />
      ) : (
        <Datagrid rowClick="edit">
            <UrlField source="link" sortable={false} />
            <TextField source="provider" sortable={false} />
            <DateField source="isoDate" sortable={false} showTime={true} />
            <BooleanField source="preferPubDate" sortable={false} />
            {/* <ScrapButton /> */}
          </Datagrid>
      )}
    </List>
)};

export const FeedEdit = props => (
    <Edit {...props} actions={<ScrapEditActions />} >
        <SimpleForm toolbar={<CustomToolbar />}>
          <TextInput source="link" sortable={false}/>
          <TextField source="provider" />
          <TextInput source="isoDate" />
          <BooleanInput source="preferPubDate" />
        </SimpleForm>
    </Edit>
);

const useStyles = makeStyles({
  toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
  },
});

const CustomToolbar = props => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    <ScrapButton target="scraps"/>
    <DeleteButton undoable={false} />
  </Toolbar>
);
