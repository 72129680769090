import * as React from "react";
import { List, SimpleList, Datagrid, Show, SimpleShowLayout, Filter, RichTextField, BooleanField, UrlField, TextField, ImageField, DateField, SimpleForm, Create, TextInput, Edit, NumberInput, BooleanInput, AutocompleteInput } from 'react-admin';
import { SaveButton, DeleteButton, Toolbar } from 'react-admin';
import ScrapButton from '../components/actions/ScrapButton';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { Fragment } from 'react';
import { BulkDeleteButton } from 'react-admin';

const NewsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="title" alwaysOn />
        <BooleanInput source="hidden" alwaysOn />
        {/*
          <TextInput label="Title" source="title" defaultValue="Antidote" />
        <TextInput label="Title" source="title" defaultValue="Hello, World!" />
        */}
        <AutocompleteInput source="provider" choices={[
            { id: '26in', name: '26in' },
            { id: 'beta', name: 'Beta' },
            { id: 'bigbike', name: 'Big Bike' },
            { id: 'bike-mag', name: 'Bike Mag' },
            { id: 'bikeradar', name: 'Bike Radar' },
            { id: 'bikerumor', name: 'Bike Rumor' },
            { id: 'enduro-mtb', name: 'Enduro-MTB' },
            { id: 'enduro-tribe', name: 'Full Attack' },
            { id: 'flow-mtb', name: 'Flow Mtb' },
            { id: 'mtb-mag', name: 'Mtb Mag En' },
            { id: 'nsmb', name: 'NSMB' },
            { id: 'pinkbike', name: 'Pinkbike' },
            { id: 'revolution-mtb', name: 'Revolution' },
            { id: 'sick-lines', name: 'Sick Lines' },
            { id: 'singletracks', name: 'SingleTracks' },
            { id: 'vital-mtb', name: 'Vital' },
            { id: 'vojo', name: 'Vojo Mag' },
          ]} />
    </Filter>
);

export const NewsList = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const isLg = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const isXL = useMediaQuery(theme => theme.breakpoints.up('xl'));
  return (
    <List {...props} sort={{ field: 'displayDate', order: 'DESC' }} perPage={10} filters={<NewsFilter/>} bulkActionButtons={<PostBulkActionButtons />} >
      {isSmall ? (
        <SimpleList
            primaryText={record => record.title}
            secondaryText={record => `${record.provider} - ${record.hidden ? 'hidden' : 'visible'}`}
            tertiaryText={record => new Date(record.displayDate).toLocaleDateString()}
        />
      ) : (
        <Datagrid rowClick="show" >
            <ImageField source="image" sortable={false} className="thumbNailNewsView"  />
            <TextField source="title" sortable={false} />
            {isLg && (
              <UrlField source="url" sortable={false}/>
            )}
            {isXL && (
              <TextField source="extract" sortable={false} />
            )}
            <BooleanField source="readerable" sortable={false} />
            <BooleanField source="hidden" sortable={false} />
            <TextField source="provider" />
            <DateField source="displayDate" showTime={true} />
            {/*
              <DateField source="isoDate" />
              <DateField source="pubDate" />
              <BooleanField source="nativeBody" />
              <TextField source="id" />
              <TextField source="body" />
              */}
        </Datagrid>
      )}
    </List>
)};

export const NewsEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar />} >
        {/*
          <TextInput source="id" />
          */}
            <TextInput source="title" fullWidth={true} />
            <TextInput source="image" fullWidth={true}/>
            <ImageField source="image" />
            <TextInput source="url" fullWidth={true}/>
            <TextInput source="extract" fullWidth={true}/>
            <TextInput source="displayDate" />
            <DateField source="isoDate" showTime={true} />
            <DateField source="pubDate" showTime={true} />
            <BooleanInput source="visible" />
            <BooleanInput source="hidden" />
            <BooleanInput source="readerable" defaultValue={true} />
            <TextInput source="provider" />
        </SimpleForm>
    </Edit>
);
export const NewsCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="code" />
            <NumberInput source="value" />
            <TextInput source="locale" />
            <TextInput source="feedUrl" />
            <TextInput source="feedDefaultImage" />
            <TextInput source="favicon" />
            <BooleanInput source="enabled" />
        </SimpleForm>
    </Create>
);

export const NewsShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <ImageField source="image" />
            <TextField source="title" />
            <TextField source="provider" />
            <RichTextField source="extract" />
            <DateField label="Display date" source="displayDate" showTime={true} />
            <DateField label="ISO date" source="isoDate" showTime={true} />
            <DateField label="Publication date" source="pubDate" showTime={true} />
            <UrlField source="url" />
            <BooleanField source="visible" />
            <TextField label="Views" source="view" />
            <TagsField label="Tags" />
            <ImagesField label="Images" />
            <TextField label="Author" source="detail.author" />
            <RichTextField label="Content" source="detail.body" className={useStylesBody().bodyContent} />
        </SimpleShowLayout>
    </Show>
);

const TagsField = ({ record }) => (
  <ul>
      {record && record.detail && record.detail.rawTags.map(item => (
          <li key={item}>{item}</li>
      ))}
  </ul>
)
TagsField.defaultProps = {
  addLabel: true
};

const ImagesField = ({ record }) => (
  <ul style={{ listStyle:"none", display: 'flex', flexWrap: 'wrap' }}>
      {record && record.detail && record.detail.images.map(item => (
          <li style={{ margin: 5 }} key={item}>
            <img src={item} style={{ maxWidth: "10rem" }} alt="" />
          </li>
      ))}
  </ul>
)
ImagesField.defaultProps = {
  addLabel: true
};

const useStyles = makeStyles({
  toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
  },
});

const useStylesBody = makeStyles({
  bodyContent: {
    '& img': { 
      maxWidth: '100%',
      height: 'auto'
     }
  },
});

const CustomToolbar = props => (
<Toolbar {...props} classes={useStyles()}>
  <SaveButton />
  <ScrapButton target="news" />
  <DeleteButton undoable={false} />
</Toolbar>
);

const PostBulkActionButtons = props => (
  <Fragment>
      <ScrapButton label="Scrap" target="news" {...props} />
      {/* <ResetViewsButton label="Reset Views" {...props} /> */}
      {/* default bulk delete action */}
      <BulkDeleteButton {...props} />
  </Fragment>
);

export const PostList = (props) => (
  <List {...props} bulkActionButtons={<PostBulkActionButtons />}>
      ...
  </List>
);