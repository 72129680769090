import * as React from "react";
import { List, Datagrid, TextField, SimpleForm, Create, TextInput, Edit } from 'react-admin';
import { SaveButton, DeleteButton, Toolbar, SingleFieldList, ChipField, ArrayInput, SimpleFormIterator } from 'react-admin';
import ScrapButton from '../components/actions/ScrapButton';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip'

export const TagsList = props => {
  return (
    <List {...props} sort={{ field: 'name', order: 'ASC' }} perPage={25} >
      <Datagrid rowClick="edit" >
          <TextField source="name" />
          <TextField source="slug" />
          <TextArrayField source="matchs">
              <SingleFieldList>
                  <ChipField source="id" />
              </SingleFieldList>
          </TextArrayField>
      </Datagrid>
    </List>
  )
}

export const TagsEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar />} >
            <TextField source="id" />
            <TextInput source="name" />
            <TextInput source="slug" />
            <ArrayInput source="matchs">
                <SimpleFormIterator>
                    <TextInput source="" />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);

export const TagsCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="slug" />
        </SimpleForm>
    </Create>
);

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
  });
  
const CustomToolbar = props => (
<Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    <ScrapButton target="providers" />
    <DeleteButton undoable={false} />
</Toolbar>
);

const TextArrayField = ({ record, source }) => {
    const array = record[source]
    if (typeof array === 'undefined' || array === null || array.length === 0) {
      return <div/>
    } else {
      return (
        <>
          {array.map(item => <Chip label={item} key={item}/>)}
        </>
      )    
    }
  }
  TextArrayField.defaultProps = { addLabel: true }