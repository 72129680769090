// in src/comments/CommentEditActions.js
import React from 'react';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { TopToolbar, ListButton } from 'react-admin';

const ScrapEditActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />
        {/* <ShowButton basePath={basePath} record={data} /> */}
        {/*
        <ScrapButton record={data} />
        <CardActions style={cardActionStyle}>
            <ScrapButton record={data} />
        </CardActions>
        */}
    </TopToolbar>
    );

export default ScrapEditActions;