import * as React from "react";
import { List, SimpleList, Datagrid, BooleanField, DateField, TextField, ImageField, NumberField, SimpleForm, Create, TextInput, AutocompleteInput, Edit, NumberInput, BooleanInput, Filter } from 'react-admin';
import { SaveButton, DeleteButton, Toolbar } from 'react-admin';
import ScrapButton from '../components/actions/ScrapButton';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

const ProvidersFilter = (props) => (
    <Filter {...props}>
        <BooleanInput source="archived" alwaysOn />
        <BooleanInput source="enabled" alwaysOn />
    </Filter>
);

export const ProviderList = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  return (
    <List {...props} sort={{ field: 'name', order: 'ASC' }} perPage={25} filters={<ProvidersFilter />} filterDefaultValues={{ archived: false, enabled: true }}>
      {isSmall ? (
        <SimpleList
            primaryText={record => record.name}
            secondaryText={record => `${record.value} - ${record.enabled ? "enabled" : "disabled"} ${record.archived ? " - archived" : ""}`}
            tertiaryText={record => `${record.locale}`}
        />
      ) : (
        <Datagrid rowClick="edit" >
            <TextField source="name" />
            <TextField source="code" />
            <NumberField source="value" />
            <TextField source="locale" />
            <BooleanField source="enabled" />
            <BooleanField source="readerable" />
            <DateField source="lastItemDate" showTime={true} />
            <ImageField source="favicon" title="favicon" className="thumbNailView" />
            <ImageField source="feedDefaultImage" title="feedDefaultImage" className="thumbNailView" />
            {/*
              <TextField source="id" />
              <TextField source="feedUrl" />
              <TextField source="favicon" />
              <TextField source="feedDefaultImage" />
              */}
        </Datagrid>
      )}
    </List>
  )
}

export const ProviderEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar />} >
            <TextField source="id" />
            <TextInput source="name" />
            <TextInput source="code" />
            <NumberInput source="value" />
            {/* <AutocompleteInput source="priority" choices={[
                { id: '1', name: '1' },
                { id: '2', name: '2' },
                { id: '3', name: '3' },
                { id: '4', name: '4' },
                { id: '5', name: '5' },
            ]} /> */}
            <AutocompleteInput source="locale" choices={[
                { id: 'fr', name: 'FR' },
                { id: 'en', name: 'EN' },
            ]} />
            <TextInput source="siteUrl" fullWidth={true} />
            <TextInput source="feedUrl" fullWidth={true} />
            <TextInput source="feedDefaultImage" fullWidth={true} />
            <TextInput source="favicon" fullWidth={true} />
            <TextInput source="lastItemDate" />
            <BooleanInput source="preferPubDate" />
            <BooleanInput source="preferFeedUrl" />
            <BooleanInput source="enabled" />
            <BooleanInput source="archived" />
            <BooleanInput source="readerable" default={true} />
            <TextInput source="contentSelectors" fullWidth={true} format={a => (a ? a.join(', ') : '')} parse={s => s.split(',').map(item => item.trim())} />
            <TextInput source="excludeSelectors" fullWidth={true} format={a => (a ? a.join(', ') : '')} parse={s => s.split(',').map(item => item.trim())} />
            {/* <ArrayInput source="contentSelectors">
                <SimpleFormIterator>
                    <TextInput source="" />
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="excludeSelectors">
                <SimpleFormIterator>
                    <TextInput source="" />
                </SimpleFormIterator>
            </ArrayInput> */}
        </SimpleForm>
    </Edit>
);

export const ProviderCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="code" />
            <NumberInput source="value" />
            <AutocompleteInput source="locale" choices={[
                { id: 'fr', name: 'FR' },
                { id: 'en', name: 'EN' },
            ]} />
            <TextInput source="siteUrl" fullWidth={true} />
            <TextInput source="feedUrl" fullWidth={true} />
            <TextInput source="feedDefaultImage" fullWidth={true} />
            <TextInput source="favicon" fullWidth={true} />
            <BooleanInput source="enabled" initialValue={false} />
            <BooleanInput source="archived" initialValue={false} />
            <BooleanInput source="readerable" initialValue={false} />
        </SimpleForm>
    </Create>
);

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
  });
  
const CustomToolbar = props => (
<Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    <ScrapButton target="providers" />
    <DeleteButton undoable={false} />
</Toolbar>
);
