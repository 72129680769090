import React, { useState, useEffect } from 'react';
import { useMediaQuery, Theme } from '@material-ui/core';
import jwt from 'jsonwebtoken';
import Welcome from './Welcome'
import Trending from './Trending'
import CardWithIcon from './CardWithIcon';
import TodayIcon from '@material-ui/icons/Today';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { connect } from "react-redux";

const API_URL = process.env.REACT_APP_CHAIN_API_URL

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const Dashboard = (props) => {
    const [data, setData] = useState({ trending: [], counts: {}, refresh: -1 });
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );
    useEffect(() => {
        if (data.refresh === props.views) return
        else {
            const fetchData = async () => {
                console.log('refresh dashboard')
                const token = jwt.sign({ source: 'admin' }, 'hello world', { expiresIn: '1h' })
                const result = await fetch(API_URL+'/api/news/dashboard', {
                    method: 'GET',
                    headers: {
                        'Content-Type':'application/json',
                        'X-Source-Header':`ra-admin`,
                        'Authorization': `Bearer ${token}`
                    },
                })
                const json = await result.json()
                setData({ 
                    trending: json.data.trending,
                    counts: json.data.counts,
                    refresh: data.refresh+1
                });
            };
            fetchData()
        }
    });

    return isXSmall ? (
        <>
            <VerticalSpacer />
                <CardWithIcon
                    to="/news"
                    icon={TodayIcon}
                    title="Today"
                    subtitle={data.counts.today}
                />
                <VerticalSpacer />
                <CardWithIcon
                    to="/news"
                    icon={TodayIcon}
                    title="Yesterday"
                    subtitle={data.counts.yesterday}
                />
                <VerticalSpacer />
                <CardWithIcon
                    to="/news"
                    icon={MenuBookIcon}
                    title="Readerable"
                    subtitle={data.counts.readerable}
                />
                <VerticalSpacer />
                <CardWithIcon
                    to="/news"
                    icon={LibraryBooksIcon}
                    title="Total"
                    subtitle={data.counts.total}
                />
            <VerticalSpacer />
            <Trending trending={data.trending} />
        </>
        ) : isSmall ? (
        <>
            <VerticalSpacer />
            <div style={styles.flex}>
                <CardWithIcon
                    to="/news"
                    icon={TodayIcon}
                    title="Today"
                    subtitle={data.counts.today}
                />
                <Spacer />
                <CardWithIcon
                    to="/news"
                    icon={TodayIcon}
                    title="Yesterday"
                    subtitle={data.counts.yesterday}
                />
            </div>
            <VerticalSpacer />
            <div style={styles.flex}>
                <CardWithIcon
                    to="/news"
                    icon={MenuBookIcon}
                    title="Readerable"
                    subtitle={data.counts.readerable}
                />
                <Spacer />
                <CardWithIcon
                    to="/news"
                    icon={LibraryBooksIcon}
                    title="Total"
                    subtitle={data.counts.total}
                />
            </div>
            <VerticalSpacer />
            <Trending trending={data.trending} />
        </>
        ) : (
        <>
            <div style={styles.flex}>
                <CardWithIcon
                    to="/news"
                    icon={TodayIcon}
                    title="Today"
                    subtitle={data.counts.today}
                />
                <Spacer />
                <CardWithIcon
                    to="/news"
                    icon={TodayIcon}
                    title="Yesterday"
                    subtitle={data.counts.yesterday}
                />
                <Spacer />
                <CardWithIcon
                    to="/news"
                    icon={MenuBookIcon}
                    title="Readerable"
                    subtitle={data.counts.readerable}
                />
                <Spacer />
                <CardWithIcon
                    to="/news"
                    icon={LibraryBooksIcon}
                    title="Total"
                    subtitle={data.counts.total}
                />
            </div>
            <VerticalSpacer />
            <Trending trending={data.trending} />
            <VerticalSpacer />
            <Welcome />
        </>
    )
}

const mapStateToProps = state => ({ views: state.admin.ui.viewVersion });

export default connect(
    mapStateToProps,
    {}
  )(Dashboard);
// export default Dashboard;
