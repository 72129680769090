// in src/comments/ApproveButton.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showNotification } from 'react-admin';
import jwt from 'jsonwebtoken';

const API_URL = process.env.REACT_APP_CHAIN_API_URL

class ScrapButton extends Component {
    handleClick = () => {
        const { record, showNotification, target, selectedIds } = this.props;
        // const updatedRecord = { ...record, toScrap: true };

        const token = jwt.sign({ source: 'admin' }, 'hello world', { expiresIn: '1h' })
        const targetIds = []
        if (record) {
            targetIds.push(record.id)
        } else {
            targetIds.push(...selectedIds)
        }

        const scrapPromises = targetIds.map(item => 
            fetch(API_URL+`/api/${target}/${item}/scrap`, {
                method: 'GET',
                headers: {
                  'Content-Type':'application/json',
                  'X-Source-Header':`ra-admin`,
                  'Authorization': `Bearer ${token}`
                },
                //body: JSON.stringify(updatedRecord)
              })
              .then((res) => res.json())
              .then((res) => {
                  console.log("res", res)
                  if (target === 'news') showNotification('Scrap done for '+res.data.docNews.provider + ' / '+res.data.docNews.title)
                  else showNotification('Scrap done')
              })
              .catch((e) => {
                  showNotification('Scrap error: '+e, 'warning')
              })
            )
        return Promise.allSettled(scrapPromises)
    }

    render() {
        return <Button onClick={this.handleClick} >Scrap</Button>;
    }
}

ScrapButton.propTypes = {
    record: PropTypes.object,
    showNotification: PropTypes.func,
};

export default connect(null, { showNotification, })(ScrapButton)