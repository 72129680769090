import React from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction, Avatar } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary,
    },
}));

const Dashboard = ({ trending }) => {
    const classes = useStyles();
    return (
        <Card className={classes.root} >
            <CardHeader title="Trending posts" />   
            <CardContent>
                <List>
                    {trending ? trending.slice(0, 10).map((record) => (
                            <ListItem
                                button
                                to={`/news/${record.id}`}
                                component={Link}
                                key={record.id}
                            >
                                <ListItemAvatar>
                                    <Avatar src={`${record.image}`} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`${record.title}`}
                                    secondary={`${record.displayDate.split('T')[0]} - ${record.provider}`}
                                />
                                <ListItemSecondaryAction>
                                    <span>
                                        {record.view} views
                                    </span>
                                </ListItemSecondaryAction>
                        </ListItem>
                        ))
                        : null}
                </List>
            </CardContent>
        </Card>
    )
}
export default Dashboard;
