// in src/App.js
import * as React from "react";
import { fetchUtils, Admin, Resource } from 'react-admin';
import { ProviderList, ProviderEdit, ProviderCreate } from './resources/providers';
import { NewsList, NewsShow, NewsEdit } from './resources/news';
import { TagsList, TagsCreate, TagsEdit } from './resources/tags';
import { FeedList, FeedEdit } from './resources/feeds';
import { FirebaseAuthProvider } from 'react-admin-firebase';
import { createMuiTheme } from '@material-ui/core/styles';
import CustomLayout from './components/layout/Layout';
import CustomLoginPage from './components/login/CustomLoginPage';
import Dashboard from './components/dashboard/Dashboard';
import UserIcon from '@material-ui/icons/SettingsApplications';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LabelIcon from '@material-ui/icons/Label';
import jsonServerProvider from 'ra-data-json-server';
import jwt from 'jsonwebtoken';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_CHAIN_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_CHAIN_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_CHAIN_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_CHAIN_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_CHAIN_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_CHAIN_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_CHAIN_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_CHAIN_FIREBASE_MEASUREMENT_ID
}
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#8bc34a', // Chain green
      dark: '#ba000d',
      contrastText: '#000',
    },
    type: 'light', // Switching the dark mode on is a single property value change.
  },
  // palette: {
  //     primary: indigo,
  //     secondary: pink,
  //     error: red,
  //     contrastThreshold: 3,
  //     tonalOffset: 0.2,
  // },
  // palette: {
  //   type: 'dark', // Switching the dark mode on is a single property value change.
  // },
});


const options = {
  dontwatch: ['news', 'scrapTest', 'feedItems'],
  logging: true,
  lazyLoading: {
    enabled: true
  },
  // Logging of all reads performed by app (additional feature, for lazy-loading testing)
  firestoreCostsLogger: {
    enabled: true,
  }
};

// const dataProvider = FirebaseDataProvider(firebaseConfig, options);
const authProvider = FirebaseAuthProvider(firebaseConfig, options);
const authorizedIds = ['3S2sCbe95lVteaaHIa6Tx4uHxyE2', '1uc8OouWZ7dRtoqXWYX0fFZzWWO2']
// Use this just like the normal auth provider
const filterAuthProvider = {
  // Copy all authprovider functionality
  ...authProvider,
  checkAuth: async () => {
    const user = await authProvider.checkAuth()
    console.log("check user")
    if (user && authorizedIds.includes(user.uid)) {
      return user
    }
    // Make sure user is logged out, if not an admin
    await authProvider.logout()
    throw new Error("Login error, invalid permissions")
  },
};

const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' })
  }
  // add your own headers here
  const token = jwt.sign({ source: 'admin' }, 'hello world', { expiresIn: '1h' })

  options.headers.set('X-Source-Header', 'ra-admin')
  options.headers.set('Authorization', `Bearer ${token}`)
  options.headers.set('Access-Control-Allow-Origin', '*')
  
  return fetchUtils.fetchJson(url, options)
}

// const providerHost = 'https://us-central1-chain-app-6dd95.cloudfunctions.net'
// const providerHost = 'http://localhost:5001/chain-app-6dd95/us-central1/app/api'
// const providerHost = 'https://data.chainapp.news/api'
const API_URL = process.env.REACT_APP_CHAIN_API_URL
const providerHost = API_URL+'/api'
const dataProvider = jsonServerProvider(providerHost, httpClient)

const App = () => (
    <Admin
        title={"Chain Admin"}
        dashboard={Dashboard}
        theme={theme}
        loginPage={CustomLoginPage}
        layout={CustomLayout}
        authProvider={filterAuthProvider}
        dataProvider={dataProvider} >
        <Resource name="providers" list={ProviderList} edit={ProviderEdit} create={ProviderCreate} icon={UserIcon}/>
        <Resource name="news" show={NewsShow} list={NewsList} edit={NewsEdit} icon={LibraryBooksIcon} />
        <Resource name="tags" list={TagsList} edit={TagsEdit} create={TagsCreate} icon={LabelIcon} />
        <Resource name="scraps"  list={FeedList} edit={FeedEdit} icon={RssFeedIcon}/>
    </Admin>
);
export default App;
